const theme = {
  // Heading
  heading: "#484856",
  headingLight: "rgb(109, 114, 124)",
  infoText: "#444444",
  link: "#2193b0",

  jumbotronBackground: "#f5f9fe",
  footerBackground: "#f2f2f2",

  //Buttons
  button: "rgb(0, 122, 255)",
  buttonBorder: "#d6d6d6",

  //Colors
  primaryLight: "#2b6684",
  primaryDark: "#012e67",
  primaryDeep: "#032e42",
  primaryGrey: "#9cacbf",
  primaryWhite: "#ffffff",

  secondaryDark: "#22313F",
  secondaryLight: "#2B75AC",
  backgroundGrey: "#F5F6F7",
  black: "#000000",
  lightgrey: "#9C9A9A",
  keyTermsBlue: "#DCE6F1",
  textGrey: "#7A7A7A",
  linkBlue: "#2691be",
  linkLightBlue: "rgb(29, 155, 240)",

  //Media Queries

  /* Extra small devices (phones, 600px and down) */
  extraSmall: "@media only screen and (max-width: 600px)",
  /* Small devices (portrait tablets and large phones, 600px and up) */
  small: "@media only screen and (min-width: 600px)",
  /* Medium devices (landscape tablets, 768px and up) */
  medium: "@media only screen and (min-width: 768px)",
  /* Large devices (laptops/desktops, 992px and up) */
  large: "@media only screen and (min-width: 992px)",
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  extraLarge: "@media only screen and (min-width: 1200px)",

  //Sizes
  contentHeight: "calc(100vh - 50px)",
};

// Mimic MUI <Alert> background/text colors for each severity.
// "critical" is a custom style based on ClassifierSummary.jsx
export const severityColors = {
  success: {
    backgroundColor: "#edf7ed", // pale green
    color: "#1e4620", // dark green text
  },
  info: {
    backgroundColor: "#e8f4fd", // pale blue
    color: "#0c5460", // darker blue text
  },
  warning: {
    backgroundColor: "#fff4e5", // pale orange
    color: "#856404", // darker orange text
  },
  error: {
    backgroundColor: "#fdecea", // pale red
    color: "#611a15", // dark red text
  },
  critical: {
    backgroundColor: "#ffebee", // light red
    color: "#b71c1c", // darkest red text
    border: "1px solid #f44336", // optional border to highlight critical
  },
  unknown: {
    backgroundColor: "#f0f0f0",
    color: "#333333",
  },
  transcribing: {
    backgroundColor: "#DCE6F1", // using theme's keyTermsBlue
    color: "#22313F", // using theme's secondaryDark
  },
};

export default theme;
