import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import MainJumbotron from "../components/MainJumbotron.jsx";
import SEO from "../components/SEO.jsx";
import StyledButton from "../components/StyledButton.jsx";
import Loader from "../components/Loader.jsx";
import { useHistory, Link } from "react-router-dom";
import { getProject } from "../utils/queries.js";
import { classifierRejected, classifierAccepted } from "../utils/mutations.js";
import { UserContext } from "../utils/context.js";
import { KEY_TERMS_BOLD_DELIMINATOR } from "../utils/constants.js";
import {
  getKeyTermsOnlyJSX,
  getFinalStringJSX,
} from "../utils/helper functions/keyterms_contains_helper.js";
import { severityColors } from "../utils/theme.js";

// -----------------------------------------------------------------------------
// Styled Components for the Evidence Page layout and design
// -----------------------------------------------------------------------------

// Main wrapper for the Evidence Page
const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 800px;
`;

// Wrapper for the top row which includes the heading and buttons
const TopRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
  justify-content: space-between;
  align-items: center;
`;

// Styled component for the top heading text
const TopHeading = styled.div`
  font-weight: bold;
  font-size: 1.75rem;
  margin-left: 20px;
`;

// Wrapper for the buttons at the top right of the page
const TopButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

// Wrapper for the body section where evidence items are listed
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid lightgrey;
  margin: 0px 20px 20px;
  padding-top: 20px;
  box-sizing: border-box;
  flex-grow: 1;
`;

// Styled component for each individual evidence card/container
const EvidenceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid lightgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 25px;
  padding: 10px 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
`;

// Styled component for the top part of an evidence card (file info and action buttons)
const EvidenceTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${(props) => props.theme.textGrey};
  font-size: 0.85rem;
`;

// Container for the action buttons within each evidence card
const EvidenceButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

// Styled component for each evidence action button (as text with pointer cursor)
const EvidenceButton = styled.div`
  margin: 0px 10px;
  cursor: pointer;
  color: ${(props) => props.theme.linkBlue};
`;

// Styled Link component to view the transcript of a call
const EvidenceLink = styled(Link)`
  margin: 0px 10px;
  cursor: pointer;
  color: ${(props) => props.theme.linkBlue};
  text-decoration: none;
`;

// Styled component for the body section within each evidence card (e.g., tags, summary)
const EvidenceBody = styled.div`
  flex-grow: 1;
  margin-top: 10px;
`;

// Styled component for evidence tags, e.g., classifier tags or key terms
const EvidenceTags = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  margin-left: 5px;
`;

// Styled component for the evidence text or summary
const EvidenceText = styled.div`
  margin-left: 5px;
`;

// Styled component to display a message when no evidence is found
const NoneFound = styled.div`
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
  color: grey;
  flex-grow: 1;
  align-items: center;
`;

// Styled component for a subheading used in marked evidence details
const MarkedEvidenceSubHeading = styled.div`
  font-weight: 600;
`;

// -----------------------------------------------------------------------------
// Badge Functionality
// -----------------------------------------------------------------------------

/**
 * Returns a severity badge (a styled <span>) based on the classifierSeverityScore.
 * The mapping is as follows:
 *   - Score 1 or 2: "Low" (using severityColors.info)
 *   - Score 3: "Medium" (using severityColors.warning)
 *   - Score 4: "High" (using severityColors.error)
 *   - Score 5: "Critical" (using severityColors.critical)
 * For any unexpected value, "Unknown" is used.
 */
const getSeverityBadge = (call) => {
  const score = call.classifierSeverityScore;
  let label = "";
  let style = {};
  if (score === 1 || score === 2) {
    label = "Low";
    style = severityColors.info;
  } else if (score === 3) {
    label = "Medium";
    style = severityColors.warning;
  } else if (score === 4) {
    label = "High";
    style = severityColors.error;
  } else if (score === 5) {
    label = "Critical";
    style = severityColors.critical;
  } else {
    label = "Unknown";
    style = severityColors.unknown;
  }
  return (
    <span
      style={{
        ...style,
        padding: "3px 8px",
        borderRadius: "4px",
        fontWeight: "bold",
        marginLeft: "10px",
        fontSize: "0.75rem",
      }}
    >
      {label}
    </span>
  );
};

// -----------------------------------------------------------------------------
// Accordion Components for Low Severity Calls
// -----------------------------------------------------------------------------

const AccordionContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
`;

const AccordionHeader = styled.div`
  background-color: #f7f7f7;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  user-select: none;
`;

const AccordionContent = styled.div`
  padding: 10px;
  display: ${(props) => (props.expanded ? "block" : "none")};
`;

/**
 * Returns a component that displays low severity calls in an accordion.
 */
const LowSeverityAccordion = ({ lowCalls, onAccept, onReject }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <AccordionContainer>
      <AccordionHeader onClick={() => setExpanded(!expanded)}>
        Low Severity Calls ({lowCalls.length}) {expanded ? "▲" : "▼"}
      </AccordionHeader>
      <AccordionContent expanded={expanded}>
        {lowCalls.map((call) => (
          <EvidenceWrapper key={call.id}>
            <EvidenceTop>
              <div style={{ flexGrow: 1 }}>
                {call.audioFileName} - {call.date} - {call.durationString}
                {getSeverityBadge(call)}
              </div>
              <EvidenceButtonsWrapper>
                <EvidenceLink to={`/call/${call.id}`}>
                  View Transcript
                </EvidenceLink>
                <EvidenceButton
                  onClick={async () => {
                    let id = await classifierAccepted(call.id);
                    if (id === call.id) {
                      onAccept(call.id);
                    } else {
                      console.error(
                        "Could not update database to accept classifier"
                      );
                      console.log(id);
                    }
                  }}
                >
                  Mark Call as Evidence
                </EvidenceButton>
                <EvidenceButton
                  onClick={async () => {
                    let id = await classifierRejected(call.id);
                    if (id === call.id) {
                      onReject(call.id);
                    } else {
                      console.error(
                        "Could not update database to reject classifier"
                      );
                      console.log(id);
                    }
                  }}
                >
                  Not Relevant
                </EvidenceButton>
              </EvidenceButtonsWrapper>
            </EvidenceTop>
            <EvidenceBody>
              <EvidenceTags>{call.classifierTags.join(", ")}</EvidenceTags>
              {call.classifierSummary}
            </EvidenceBody>
            {call.contains.length > 0 && (
              <EvidenceBody>
                <MarkedEvidenceSubHeading>Key Terms:</MarkedEvidenceSubHeading>
                <EvidenceTags>
                  {getKeyTermsOnlyJSX(
                    call.contains,
                    KEY_TERMS_BOLD_DELIMINATOR
                  )}
                </EvidenceTags>
                <EvidenceText>{getFinalStringJSX(call.contains)}</EvidenceText>
              </EvidenceBody>
            )}
            {call.userMarkedHasEvidence && (
              <EvidenceBody>
                <MarkedEvidenceSubHeading>
                  Manually Marked as Evidence
                </MarkedEvidenceSubHeading>
              </EvidenceBody>
            )}
          </EvidenceWrapper>
        ))}
      </AccordionContent>
    </AccordionContainer>
  );
};

// -----------------------------------------------------------------------------
// EvidencePage Component
// -----------------------------------------------------------------------------
const EvidencePage = ({ match }) => {
  // State for storing the current case (project) details from AWS
  const [currentCase, setCurrentCase] = useState({
    title: "",
    dateMade: "",
    type: "",
    callCount: "",
    totalDuration: "",
    status: "",
    calls: [],
  });
  // State for calls that need classifier review (evidence found by classifier but not yet reviewed)
  const [classifierNeedsReviewCalls, setClassifierNeedsReviewCalls] = useState(
    []
  );
  // State for calls that have been marked as evidence (either manually or by classifier)
  const [callsContainingMarkedEvidence, setCallsContainingMarkedEvidence] =
    useState([]);
  // Flag to indicate if calls have been retrieved from AWS
  const [retrievedCalls, setRetrievedCalls] = useState(false);
  // Boolean to toggle between reviewing marked evidence and unreviewed evidence
  const [reviewingMarkedEvidence, setReviewingMarkedEvidence] = useState(false);

  // React Router history and User Context
  const history = useHistory();
  const user = useContext(UserContext);

  // ---------------------------------------------------------------------------
  // Fetch the case (project) details from AWS when component mounts or user changes
  // ---------------------------------------------------------------------------
  useEffect(() => {
    const getCase = async () => {
      const caseID = match.params.id;
      const projectInfo = await getProject(caseID, false);
      if (typeof projectInfo === "string") {
        // If an error occurs, log it and redirect to unauthorized page
        console.error(`Failed to receive project due to error: ${projectInfo}`);
        history.push("/unauthorized");
        return;
      }
      setCurrentCase(projectInfo);
    };
    if (user) {
      getCase();
    }
  }, [match.params.id, user, history]);

  // ---------------------------------------------------------------------------
  // Process the calls to separate those that need review from those already marked,
  // and sort them by severity score (descending: 5 first)
  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (currentCase.calls.length > 0) {
      let markedEvidence = [];
      let classifierReview = [];
      // Loop through every call and classify based on evidence indicators
      for (const call of currentCase.calls) {
        // If call has been manually marked, has accepted classifier evidence, or contains key terms, add to marked evidence
        if (
          call.userMarkedHasEvidence ||
          (call.classifierContainsEvidence &&
            call.classifierResultWasAccepted) ||
          call.contains.length > 0
        ) {
          markedEvidence.push(call);
        }
        // If classifier found evidence but it hasn't been reviewed, add to the review list
        if (
          call.classifierContainsEvidence &&
          !call.classifierHasBeenReviewed
        ) {
          classifierReview.push(call);
        }
      }
      /*  Sort both arrays in descending order by classifierSeverityScore */
      classifierReview.sort(
        (a, b) => b.classifierSeverityScore - a.classifierSeverityScore
      );
      markedEvidence.sort(
        (a, b) => b.classifierSeverityScore - a.classifierSeverityScore
      );

      setClassifierNeedsReviewCalls(classifierReview);
      setCallsContainingMarkedEvidence(markedEvidence);
      // If there is no classifier review needed, default to showing marked evidence
      if (classifierReview.length === 0) {
        setReviewingMarkedEvidence(true);
      }
      setRetrievedCalls(true);
    }
  }, [currentCase.calls]);

  // ---------------------------------------------------------------------------
  // Update local state when classifier result is accepted/rejected
  // ---------------------------------------------------------------------------
  const classifierLocalChange = (callId, accepted) => {
    // Find the index of the call in the unreviewed (needs review) array
    const index = classifierNeedsReviewCalls.findIndex(
      (call) => call.id === callId
    );
    if (index >= 0) {
      if (accepted) {
        // If accepted, check if the call is already in the marked evidence array
        let markedIndex = callsContainingMarkedEvidence.findIndex(
          (call) => call.id === callId
        );
        if (markedIndex !== -1) {
          // If already present, update its review flags and update state
          let newMarkedEvidence = [...callsContainingMarkedEvidence];
          newMarkedEvidence[markedIndex].classifierHasBeenReviewed = true;
          newMarkedEvidence[markedIndex].classifierResultWasAccepted = true;
          setCallsContainingMarkedEvidence(newMarkedEvidence);
        } else {
          // Otherwise, update the call in the unreviewed list and add it to marked evidence
          classifierNeedsReviewCalls[index].classifierHasBeenReviewed = true;
          classifierNeedsReviewCalls[index].classifierResultWasAccepted = true;
          setCallsContainingMarkedEvidence((oldCalls) => [
            ...oldCalls,
            classifierNeedsReviewCalls[index],
          ]);
        }
      }
      // Remove the call from the unreviewed array since it has now been reviewed
      let updatedArray = [...classifierNeedsReviewCalls];
      updatedArray.splice(index, 1);
      if (updatedArray.length === 0) {
        setReviewingMarkedEvidence(true);
      }
      setClassifierNeedsReviewCalls(updatedArray);
    } else {
      console.warn("Call already removed from classifier needs review list");
    }
  };

  // ---------------------------------------------------------------------------
  // Build JSX for reviewing classifier evidence (calls that need review)
  // ---------------------------------------------------------------------------
  const buildClassifierReviewJSX = () => {
    if (retrievedCalls) {
      let highCalls = [];
      let lowCalls = [];
      // Separate high severity calls (score >= 3) and low severity calls (score 1-2)
      for (const call of classifierNeedsReviewCalls) {
        if (call.classifierSeverityScore < 3) {
          lowCalls.push(call);
        } else {
          highCalls.push(call);
        }
      }
      /*  Sort highCalls and lowCalls in descending order by classifierSeverityScore */
      highCalls.sort(
        (a, b) => b.classifierSeverityScore - a.classifierSeverityScore
      );
      lowCalls.sort(
        (a, b) => b.classifierSeverityScore - a.classifierSeverityScore
      );

      let jsx = [];
      // Map high severity calls normally
      for (const call of highCalls) {
        jsx.push(
          <EvidenceWrapper key={call.id}>
            <EvidenceTop>
              {/* Display basic call info with severity badge */}
              <div style={{ flexGrow: 1 }}>
                {call.audioFileName} - {call.date} - {call.durationString}
                {getSeverityBadge(call)}
              </div>
              <EvidenceButtonsWrapper>
                {/* Link to view the full transcript of the call */}
                <EvidenceLink to={`/call/${call.id}`}>
                  View Transcript
                </EvidenceLink>
                {/* Button to accept classifier evidence */}
                <EvidenceButton
                  onClick={async () => {
                    let id = await classifierAccepted(call.id);
                    if (id === call.id) {
                      classifierLocalChange(call.id, true);
                    } else {
                      console.error(
                        "Could not update database to accept classifier"
                      );
                      console.log(id);
                    }
                  }}
                >
                  Mark Call as Evidence
                </EvidenceButton>
                {/* Button to reject classifier evidence */}
                <EvidenceButton
                  onClick={async () => {
                    let id = await classifierRejected(call.id);
                    if (id === call.id) {
                      classifierLocalChange(call.id, false);
                    } else {
                      console.error(
                        "Could not update database to reject classifier"
                      );
                      console.log(id);
                    }
                  }}
                >
                  Not Relevant
                </EvidenceButton>
              </EvidenceButtonsWrapper>
            </EvidenceTop>
            <EvidenceBody>
              {/* Display classifier tags and summary */}
              <EvidenceTags>{call.classifierTags.join(", ")}</EvidenceTags>
              {call.classifierSummary}
            </EvidenceBody>
          </EvidenceWrapper>
        );
      }
      // If there are low severity calls, add an accordion for them
      if (lowCalls.length > 0) {
        jsx.push(
          <LowSeverityAccordion
            key="low-severity"
            lowCalls={lowCalls}
            onAccept={(id) => classifierLocalChange(id, true)}
            onReject={(id) => classifierLocalChange(id, false)}
          />
        );
      }
      // If no evidence exists, show a message
      if (jsx.length === 0) {
        jsx.push(
          <NoneFound key="No New or Unreviewed Evidence Found">
            No New or Unreviewed Evidence Found
          </NoneFound>
        );
      }
      return jsx;
    } else {
      // While calls are being retrieved, show a loader
      return <Loader />;
    }
  };

  // ---------------------------------------------------------------------------
  // Build JSX for all items that have been marked as evidence
  // ---------------------------------------------------------------------------
  const buildMarkedEvidenceJSX = () => {
    if (retrievedCalls) {
      let jsx = [];
      for (const call of callsContainingMarkedEvidence) {
        jsx.push(
          <EvidenceWrapper key={call.id}>
            <EvidenceTop>
              {/* Display basic call info with severity badge */}
              <div style={{ flexGrow: 1 }}>
                {call.audioFileName} - {call.date} - {call.durationString}
                {getSeverityBadge(call)}
              </div>
              <EvidenceButtonsWrapper>
                {/* Link to view the transcript */}
                <EvidenceLink to={`/call/${call.id}`}>
                  View Transcript
                </EvidenceLink>
              </EvidenceButtonsWrapper>
            </EvidenceTop>
            {/* If classifier evidence is present and accepted, display its details */}
            {call.classifierContainsEvidence &&
              call.classifierResultWasAccepted && (
                <EvidenceBody>
                  <MarkedEvidenceSubHeading>
                    Classifier:
                  </MarkedEvidenceSubHeading>
                  <EvidenceTags>{call.classifierTags.join(", ")}</EvidenceTags>
                  <EvidenceText>{call.classifierSummary}</EvidenceText>
                </EvidenceBody>
              )}
            {/* If key terms are present, display them */}
            {call.contains.length > 0 && (
              <EvidenceBody>
                <MarkedEvidenceSubHeading>Key Terms:</MarkedEvidenceSubHeading>
                <EvidenceTags>
                  {getKeyTermsOnlyJSX(
                    call.contains,
                    KEY_TERMS_BOLD_DELIMINATOR
                  )}
                </EvidenceTags>
                <EvidenceText>{getFinalStringJSX(call.contains)}</EvidenceText>
              </EvidenceBody>
            )}
            {/* If the call was manually marked as evidence, indicate that */}
            {call.userMarkedHasEvidence && (
              <EvidenceBody>
                <MarkedEvidenceSubHeading>
                  Manually Marked as Evidence
                </MarkedEvidenceSubHeading>
              </EvidenceBody>
            )}
          </EvidenceWrapper>
        );
      }
      if (jsx.length === 0) {
        jsx.push(
          <NoneFound key="No Marked Evidence Yet">
            No Marked Evidence Yet
          </NoneFound>
        );
      }
      return jsx;
    } else {
      return <Loader />;
    }
  };

  // ---------------------------------------------------------------------------
  // Render the EvidencePage component
  // ---------------------------------------------------------------------------
  return (
    <Wrapper>
      <SEO
        title="Evidence | WireTap"
        description="Search through evidence found by WireTap or your previous keyword finds."
      />
      <MainJumbotron
        title={`${currentCase.title} Evidence`}
        metrics={[
          { header: "Date Made", body: currentCase.dateMade },
          { header: "Type", body: currentCase.type },
          { header: "Call Count", body: currentCase.callCount },
          { header: "Total Call Time", body: currentCase.totalDuration },
          { header: "Status", body: currentCase.status },
        ]}
        breadcrumb={[
          { name: "My Cases", link: `/home` },
          { name: currentCase.title, link: `/case/${match.params.id}` },
          { name: "Evidence" },
        ]}
      />
      <TopRowWrapper>
        {/* Toggle the header based on whether marked evidence is being reviewed */}
        <TopHeading>
          {reviewingMarkedEvidence ? "Marked Evidence" : "Unreviewed Evidence"}
        </TopHeading>
        <TopButtonsWrapper>
          {/* Button to toggle to marked evidence view if currently showing unreviewed */}
          {!reviewingMarkedEvidence && (
            <StyledButton
              color={"white"}
              style={{
                background: "linear-gradient(#209BCF, #26B7F5)",
                border: "none",
                marginRight: "10px",
              }}
              onClick={() => {
                setReviewingMarkedEvidence(true);
              }}
              disabled={!Boolean(currentCase.title)}
            >
              View Marked Evidence
            </StyledButton>
          )}
          {/* Button to toggle to unreviewed evidence view if marked evidence exists */}
          {reviewingMarkedEvidence && classifierNeedsReviewCalls.length > 0 && (
            <StyledButton
              color={"white"}
              style={{
                background: "linear-gradient(#209BCF, #26B7F5)",
                border: "none",
                marginRight: "10px",
              }}
              onClick={() => {
                setReviewingMarkedEvidence(false);
              }}
              disabled={!Boolean(currentCase.title)}
            >
              View Unreviewed Evidence
            </StyledButton>
          )}
          {/* Button to return to the case page */}
          <StyledButton
            color={"#209BCF"}
            style={{
              background: "linear-gradient(#FFFFFF, #E9E9E9)",
            }}
            onClick={() => history.push(`/case/${match.params.id}`)}
            disabled={!Boolean(currentCase.title)}
          >
            Return to Case
          </StyledButton>
        </TopButtonsWrapper>
      </TopRowWrapper>
      <BodyWrapper>
        {/* Render either unreviewed or marked evidence based on toggle */}
        {!reviewingMarkedEvidence
          ? buildClassifierReviewJSX()
          : buildMarkedEvidenceJSX()}
      </BodyWrapper>
    </Wrapper>
  );
};

export default EvidencePage;
